import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { getrobotStatus } from './Api';
import Map from './Map';

function App() {
  return (
    <div className="App">
         <Map />
    </div>
  );
}

export default App;
