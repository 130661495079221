import React, { useEffect, useState } from 'react'

import { MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import { getrobotStatus } from './Api';
import { iconMarker } from './IconMarker';
import { robotIcon } from './robotIcon';
function Map(props) {
 
const [robotdataone, setrobotdataone] =useState()
const [robotdatatwo, setrobotdatatwo] =useState()
   let initiallat 
   let initiallong
   useEffect(()=>{
   setInterval(()=>{
    getrobotStatus('OB02221008')
    .then((res)=>{
         console.log('Get Robot Status One Response' ,res)  
         let data= res.data.data
         setrobotdataone(data)
      }
    ).catch((err)=>{
      console.log('Get Robot Status One Error' ,err)
    }) 
    getrobotStatus('OB01221007')
    .then((res)=>{
      console.log('Get Robot Status two Response' ,res) 
      let data= res.data.data
      setrobotdatatwo(data)
   }
 ).catch((err)=>{
   console.log('Get Robot Status two Error' ,err)
 }) 
   },4000) 
   
  } ,[])

  const data= [{}
    // {
    //   from_lat: "12.92415",
    //   from_long: "77.67229",
    //   id: "132512",
    //   to_lat: "12.92732",
    //   to_long: "77.63575",
    // },
    // {
    //   from_lat: "12.96691",
    //   from_long: "77.74935",
    //   id: "132513",
    //   to_lat: "12.92768",
    //   to_long: "77.62664",
    // }
]
let newdata=[]
let newdatatwo=[]
  return (

  
     <div className='Dashboard_page_Map_wrapper_Main_whole'>
      {console.log('robotdatatwo' , robotdatatwo,'robotdataone',robotdataone)}
      
                  {(()=>{
                      initiallat = parseFloat(robotdataone?.robotStatus?.latitude)                   
                      initiallong = parseFloat(robotdataone?.robotStatus?.longitude)
                  })()}
                  
            
             
           {initiallat >0  && initiallong >0 ?   
         <MapContainer center={[initiallat,initiallong]} zoom={16} scrollWheelZoom={true} style={{height:'100vh' , width:'100%'}}>
            <TileLayer
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            url="https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=qCBBfhjdskaGAD3ijjAf"
            />
             <> 
              <Marker position={[parseFloat(robotdataone?.robotStatus?.latitude),parseFloat(robotdataone?.robotStatus?.longitude)]} icon={robotIcon} >
                 <Popup>
                </Popup>
                </Marker>         
             </>  
             <>  
              <Marker position={[parseFloat(robotdatatwo?.robotStatus?.latitude),parseFloat(robotdatatwo?.robotStatus?.longitude)]} icon={robotIcon} >
                <Popup>
              </Popup>
            </Marker>
            </>  
   
         </MapContainer>
         :null}
     </div>
  )
}

export default Map
