import axios from 'axios'

const token='eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg'
export const getrobotStatus =(robotid)=>{
    return axios.post(`${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`, {
        robotId:robotid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
 }